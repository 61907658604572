import React, { Component } from 'react'

class AddGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            whgroups: [],
            modal: false
        }
    }

    openGropusModal(){
        document.body.classList.add('modal-open')
        fetch('https://app.rtpcall.ru/vendor/data/?json=bitrix-groups')
        .then((res) => {
            return res.json()
        })
        .then((res) => {
            this.setState({
                whgroups: res.dialogs,
                modal: !this.state.modal
            })
        })
    }

    closeGropusModal(){
        document.body.classList.remove('modal-open')
        this.setState({
            modal: !this.state.modal
        })
    }

    hasGroup(id){
        let groupExists = this.props.groups.filter(function(group) {
            return parseInt(id) === parseInt(group.group_id)
        })
        return groupExists.length === 0 ? false : true
    }

    render(){
        return (
            <div className="appButtonsContainer">

                <div className="appButtons">
                    <div className="get-gropus" onClick={this.openGropusModal.bind(this)}><i className="fas fa-plus"></i> Добавить группу</div>
                    <div className="remove-cache" onClick={this.props.removeAppCache} title="использовать только при необходимости"><i className="fas fa-times"></i> Удалить кэш</div>
                </div>

                <div className="groups-modal" style={this.state.modal ? {} : { display: 'none' }}>
                    <div className="groups-modal-container">
                        <div className="groups-modal-close" onClick={this.closeGropusModal.bind(this)}></div>
                        <div className="groups-modal-content">
                            {this.state.whgroups.map((group, index) => {
                                let groupExists = ( this.hasGroup(group.id) ) ? true : false
                                let addIconClass = ( groupExists ) ? 'groupExists' : 'addToGroups'
                                let addIconTitle = ( groupExists ) ? 'Добавлено' : 'Добавить'
                                return (
                                    <div className="group-item" key={index}>
                                        <span>{group.name}</span>
                                        <span className={addIconClass} onClick={() => this.props.addToGroups(group.id, group.name, groupExists)} title={addIconTitle}></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default AddGroup
