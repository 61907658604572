import React, { Component } from 'react'
import './App.css'

import Auth from './Components/Auth'
import Group from './Components/Group'
import AddGroup from './Components/AddGroup'
import Scenario from './Components/Scenario'
import Loader from './Components/Loader'

class App extends Component {
    constructor(props){
        super(props)
        this.state = {
            groups: [],
            all_scenarios: [],
            free_scenarios: [],
            loader: true,
            progress: false,
            isLoggedin: false

        }
        this.addToGroups = this.addToGroups.bind(this)
        this.removeGroup = this.removeGroup.bind(this)
        this.moveScenario = this.moveScenario.bind(this)
        this.authorization = this.authorization.bind(this)
        this.removeAppCache = this.removeAppCache.bind(this)
        this.updateBitrixData = this.updateBitrixData.bind(this)
    }


    addToGroups(id, name, groupExists){
        if(groupExists) return

        this.setState({ loader: true, progress: true })

        let data = new FormData()
        data.append("addBxGroup", JSON.stringify({
            id: id,
            name: name
        }) )

        fetch("https://app.rtpcall.ru/vendor/inc/update.php",{
            method: "POST",
            body: data
        })
        .then((res) => {
            return res.json()
        })
        .then((data) => {
            this.updateData()
        })
    }

    moveScenario(scenario_id, group_id, currentGroupId){
        let data = new FormData()
        data.append("moveScenario", JSON.stringify({
            scenario_id: scenario_id,
            group_id: group_id,
            remove_group_id: currentGroupId
        }) )

        fetch("https://app.rtpcall.ru/vendor/inc/update.php",{
            method: "POST",
            body: data
        })
        .then((res) => {
            return res.text()
        })
        .then((data) => {
            this.updateData()
        })
    }

    removeGroup(id){
        let data = new FormData()
        data.append("removeBxGroup", JSON.stringify({
            id: id
        }) )

        fetch("https://app.rtpcall.ru/vendor/inc/update.php",{
            method: "POST",
            body: data
        })
        .then((res) => {
            return res.text()
        })
        .then((data) => {
            this.updateData()
        })
    }

    authorization(username, password){
        this.setState({ loader: true, progress: true })

        let data = new FormData()
        data.append("authorization", JSON.stringify({
            username: username,
            password: password,
            session_id: Math.floor(Math.random() * 100)
        }) )

        fetch("https://app.rtpcall.ru/vendor/inc/auth.php",{
            method: "POST",
            body: data
        })
        .then((res) => {
            return res.json()
        })
        .then((data) => {
            if( data.res === "SUCCESS" ){
                this.updateData()
            }else{
                this.setState({ loader: false, progress: false }, () => {
                    alert("Неверный логин или пароль!");
                })
            }
        })
    }

    removeAppCache(){
        this.setState({ loader: true, progress: true })

        let data = new FormData()
        data.append("removeCache", null )
        fetch("https://app.rtpcall.ru/vendor/inc/update.php",{
            method: "POST",
            body: data
        })
        .then((res) => {
            return res.json()
        })
        .then((data) => {
            this.updateData()
        })
    }

    updateBitrixData(){
        fetch("https://app.rtpcall.ru/vendor/data/generators/bitrix.php")
    }

    render(){
        return (
            <div>
                { !this.state.isLoggedin ? (
                    <div>
                        <Loader loader={this.state.loader} progress={this.state.progress} />
                        <Auth authorization={this.authorization} />
                    </div>
                ) : (
                    <div className="App">
                        <Loader loader={this.state.loader} progress={this.state.progress} />

                        <AddGroup groups={this.state.groups} addToGroups={this.addToGroups} showLoadingProgress={this.showLoadingProgress} removeAppCache={this.removeAppCache} />

                        <div className="app-container">

                            <div className="groups-container">
                                {this.state.groups.map((group, index) => {
                                    return (
                                        <Group id={group.group_id} name={group.group_name} all_scenarios={this.state.all_scenarios} scenarios={group.scenarios} groups={this.state.groups} moveScenario={this.moveScenario} removeGroup={this.removeGroup} key={index} />
                                    )
                                })}
                            </div>


                            <div className="scenarios-container">
                                <h2>Сценарий</h2>
                                <div className="scenario-blocks">
                                {this.state.free_scenarios.map((scenario, index) => {
                                    return (
                                        <Scenario id={scenario.id} name={scenario.name} currentGroupId={false} groups={this.state.groups} moveScenario={this.moveScenario} fontawesome="fas fa-plus" key={index} type="forScenarios" />
                                    )
                                })}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }

            </div>
        )
    }

    updateData(progress = true){
        if(progress){
            this.setState({ loader: true, progress: true })
        }

        fetch('https://app.rtpcall.ru/vendor/data/?json=all')
        .then((res) => {
            return res.json()
        })
        .then((res) => {
            this.setState({
                groups: res.bitrix_groups,
                all_scenarios: res.all_scenarios,
                free_scenarios: res.bitrix_free_scenarios,
                loader: false,
                progress: false,
                isLoggedin: res.isLoggedin
            })
            this.updateBitrixData()
        })
    }


    componentDidMount() {
        this.updateData(false)
    }
}

export default App
