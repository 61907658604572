import React, { Component } from 'react'

class Scenario extends Component {
    constructor(props){
        super(props)
        this.state = {
            moveModal: false,
            fontawesome: "fas fa-plus"
        }
    }

    openMoveModal(){
        let fontAwesomeClass = this.state.moveModal ? "fas fa-plus" : "fas fa-minus"
        this.setState({
            moveModal: !this.state.moveModal,
            fontawesome: fontAwesomeClass
        })
    }

    moveScenario(id, group_id, currentGroupId){
        this.props.moveScenario(id, group_id, currentGroupId)
        this.setState({
            moveModal: !this.state.moveModal
        })
    }

    render(){
        let fontAwesomeClass = this.props.type === "forGroups" ? this.props.fontawesome : this.state.fontawesome
        return (
            <div className={"scenario-block opened-" + this.state.moveModal}>
                <span>{this.props.name}</span>
                <span className="joinToGroup" onClick={this.openMoveModal.bind(this)}><i className={fontAwesomeClass}></i></span>

                <div className="moveToGroup" style={this.state.moveModal ? {} : { display: 'none' }}>
                    { this.props.currentGroupId ? <span onClick={this.moveScenario.bind(this, this.props.id, false, this.props.currentGroupId)}>Удалить</span> : null }
                    {this.props.groups.map((group, index) => {
                        return (
                            <span key={index}  onClick={this.moveScenario.bind(this, this.props.id, group.group_id, this.props.currentGroupId)}>{group.group_name}</span>
                        )
                    })}
                </div>


            </div>
        )
    }


}

export default Scenario
