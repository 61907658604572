import React, { Component } from 'react'

import loader_gif from '../loader.gif'
import progress_gif from '../progress.gif'

class Loader extends Component {

    render(){
        let loaderType = (this.props.progress === true) ? 'progress' : 'loader';
        let gifSource = (this.props.progress === true) ? progress_gif : loader_gif;
        return (
            <div className={"app-loader show-" + this.props.loader + " " + loaderType}>
                <img src={gifSource} alt="" />
            </div>
        )
    }

}

export default Loader
