import React, { Component } from 'react'
import Scenario from './Scenario'

class Group extends Component {
    constructor(props){
        super(props)
        this.allowedGroups = this.allowedGroups.bind(this)
    }

    allowedGroups(){
        let groups = this.props.groups.filter((group) => {
            return group.group_id !== this.props.id
        })
        return groups
    }

    removeGroup(id){
        let allowDelete = window.confirm("Подтвердить удаление")
        if( allowDelete ){
            this.props.removeGroup(id)
        }
    }

    render(){
        return (
            <div className="groups-block">
                <h2>{this.props.name} <i className="fas fa-times" onClick={this.removeGroup.bind(this, this.props.id)}></i></h2>
                <div className="group-scenarios">
                    {this.props.scenarios.map((scenario_id, index) => {
                        if (scenario_id && this.props.all_scenarios[scenario_id]) {
                            return (
                                <Scenario id={scenario_id} name={this.props.all_scenarios[scenario_id].name} currentGroupId={this.props.id} groups={this.allowedGroups()} moveScenario={this.props.moveScenario} fontawesome="fas fa-angle-right" key={index} type="forGroups" />
                            )
                        }
                    })}
                </div>
            </div>
        )
    }

}

export default Group
