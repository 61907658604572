import React, { Component } from 'react'


class Auth extends Component {

    constructor(props){
        super(props)
        this.state = {
            username: '',
            password: ''
        }
    }

    handleSubmit(e){
        e.preventDefault();
        this.props.authorization(this.state.username, this.state.password)
    }

    setUsername(e){
        this.setState({username: e.target.value})
    }
    setPassword(e){
        this.setState({password: e.target.value})
    }

    render(){
        return (
            <div className="authorizationForm">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <input type="text" name="username" value={this.state.username} onChange={this.setUsername.bind(this)} placeholder="Логин" required />
                    <input type="password" name="password" value={this.state.password} onChange={this.setPassword.bind(this)} placeholder="Пароль" required />
                    <button>Войти</button>
                </form>
            </div>

        )
    }

}

export default Auth
